<template>
  <div :style="`font-family:${font},sans-serif`">
    <!-- <div>
      <p>{{ this.rowData }}</p>
    </div> -->
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <b-button variant="danger" @click="$router.push({ name: 'waitingkuruplun' })"> กลับ </b-button>
          <b-button class="ml-1" variant="danger" @click="qrcode"> รายการ QR Code </b-button>
          <b-button class="ml-1" variant="danger" @click="qrcode_cc"> พิมพ์ QR Code </b-button>

          <div class="title head">
            <p class="text-center h2" style="color: #558cef">รายการทรัพย์สินคงคลัง</p>
          </div>

          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">ปีงบประมาณ :</div>
            <div class="ml-1 h4">{{ this.rowData.budget_year }}</div>
            <div class="ml-1 h4 text-primary" text="primary">วันที่รับ :</div>
            <div class="ml-1 h4">
              {{ this.rowData.admitdate_th }}
            </div>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">เลขทะเบียน:</div>
            <div class="ml-1 h4">
              {{ this.rowData.equipment_code }}
            </div>
            <div class="ml-1 h4 text-primary" text="primary">ชื่อทรัพย์สิน :</div>
            <div class="ml-1 h4">{{ this.rowData.e_name }}</div>
          </div>
          <hr />

          <div class="row justify-content-end">
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel">
                      <span>
                        <feather-icon icon="FileIcon" class="mr-50" />
                        Excel</span
                      >
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>
          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
                setCurrentPage: currentPage,
              }"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'qrcode'" class="text-nowrap">
                  <b-button v-b-modal="`modal-1${props.index}`">
                    <feather-icon icon="GridIcon" class="mr-50" />
                    <span>คิวอาร์โค้ด</span>
                  </b-button>
                  <b-modal :id="`modal-1${props.index}`" class="text-nowrap" title="Qrcode" ok-only ok-title="ปิด">
                    <div class="text-center">
                      <img
                        src
                        :onerror="`fetch('${API}files/qrcode/${props.row.qrcode}',
                      {headers: {authorization:'${token}'}})
                      .then(r=>r.blob()).then(d=>
                      this.src=window.URL.createObjectURL(d))`"
                        class="mx-1"
                      />

                      <br />
                      <span class="text-nowrap">หมายเลขทรัพย์สิน : {{ props.row.e_number }}</span
                      ><br />
                      <span class="text-nowrap">Serial Number : {{ props.row.serial_number }}</span>
                    </div>
                  </b-modal>
                </span>

                <!-- Column: Action -->
                <!-- <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item>
                      <feather-icon icon="GridIcon" class="mr-50" />
                      <span>คิวอาร์โค้ด</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <feather-icon icon="ImageIcon" class="mr-50" />
                      <span>รูปภาพ</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span> -->
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => pageChanged({ currentPage: value })"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import { BFormInput, BRow, BCol, BFormGroup } from "bootstrap-vue";
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from "bootstrap-vue";
import vSelect from "vue-select";
import { BButton } from "bootstrap-vue";
import { BFormFile } from "bootstrap-vue";
import { BFormSelect } from "bootstrap-vue";
import { BFormDatepicker } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import pdfMake from "pdfmake";
import pdfFonts from "../font/custom-fonts";
import _ from "lodash";
export default {
  props: {
    rowData: Object,
  },
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
  },
  data() {
    return {
      show: false,
      API: `${API}`,
      pageLength: 100,
      currentPage: 1,
      total: "",

      dir: false,
      selected: null,
      options: [
        { value: null, text: "เลือกประเภทหลักฐาน" },
        { value: "a", text: "ใบส่งของ/ใบกำกับาษี" },
      ],
      arr_init: [],
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      columns: [
        {
          label: "หมายเลขทรัพย์สิน",
          field: "e_number",
        },
        {
          label: "Serial Number",
          field: "serial_number",
        },
        {
          label: "รายละเอียด",
          field: "information",
        },

        {
          label: "หน่วยนับ",
          field: "unittype",
        },
        {
          label: "ราคาต่อหน่วย",
          field: "unitprice",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "ราคาต่อหน่วย + vat 7%",
          field: "unitprice_vat",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        // {
        //   label: "จำนวน",
        //   field: "จำนวน",
        // },
        {
          label: "สถานะ",
          field: "reject_status",
        },

        {
          label: "คิวอาร์โค้ด/รูปภาพ",
          field: "qrcode",
        },
      ],
      rows: [],
      searchTerm: "",
      token: "",
      kk: null,
      res_data_pdf: [],
      row_ch: [],
      font: "",
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    // this.checkLogout();
    console.log(this.rowData);

    // if(this.rowData === undefined){
    //   this.$router.push({ path: '/waiting-for-pick-up'})
    // }
    _.isEmpty(this.rowData) ? this.$router.push({ path: "/waiting-for-pick-up" }) : true, this.gettable();
  },
  methods: {
    async checkLogout() {
      console.log("ตรวจสอบออกจากระบบ");
      const { username } = await JSON.parse(localStorage.getItem("username_type"));
      console.log(username);
      axios
        .get("https://eoffice.niets.or.th/verify/auth", {
          headers: {
            "px-auth-token": `${username}`,
          },
        })
        .then((response) => {
          console.log(response.status);
          if (response.status === 200) {
            console.log(" User อยู่ในระบบ");
          }
        })
        .catch((res) => {
          const { response } = res;
          console.log(response);
          if (response.status != 200) {
            console.log(" User ออกจากระบบเเล้ว");
            this.$router.push({ name: "logoutUser" });
          }
        });
      console.log("-------------------------");
    },

    _arrayBufferToBase64(buffer) {
      // console.log(buffer);
      var binary = "";
      var bytes = new Uint8Array(buffer);
      // console.log(bytes);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    async qrcode_cc() {
      // console.log(JSON.stringify(this.rows));
      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Medium.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      this.res_data_pdf = [];
      this?.rows.map((res, index) => {
        const rawData = [
          [
            {
              qr: res.e_number,
              fit: "100",
              width: "auto",
            },
            {
              text: res.e_number,
              style: "text_center",
              width: "auto",
            },
          ],
        ];

        const num = index % 5;
        this.res_data_pdf[num] = _.isEmpty(this.res_data_pdf[num]) ? rawData : [...this.res_data_pdf[num], ...rawData];
      });

      const docDefinition = {
        pageSize: "A4",
        pageOrientation: "portrait",
        info: {
          title: "คิวอาร์โค้ดทรัพย์สิน",
        },
        content: [
          {
            text: "คิวอาร์โค้ดทรัพย์สิน",
            style: "header",
            margin: [0, 0, 0, 30],
          },
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            style: "header",
          },

          {
            columns: [
              {
                text: `เลขทะเบียน : ${this.rowData.equipment_code}`,
                style: "title",
              },
              {
                text: `วันที่ :  ${parseInt(this.rowData.admitdate.split("-")[2])} ${
                  this.month[parseInt(this.rowData.admitdate.split("-")[1])]
                } ${Number(this.rowData.admitdate.split("-")[0]) + 543}`,
                style: "title",
              },

              {
                text: `ปีงบประมาณ : ${this.rowData.budget_year}`,
                style: "title",
              },
            ],
          },
          {
            columns: [
              {
                text: `ชื่อทรัพย์สิน: ${this.rowData.e_name}`,
                style: "title",
              },
            ],
            margin: [0, 0, 0, 35],
          },
          {
            style: "tableExample",
            table: {
              body: [[...this.res_data_pdf]],
            },
          },
        ],

        defaultStyle: {
          font: "Kanit",
        },

        styles: {
          tableExample: {
            alignment: "center",
          },
          tableHeader: {
            bold: true,
            fontSize: 9,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
            alignment: "center",
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 15, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            alignment: "center",
            fontSize: 7,
          },
          text: {
            fontSize: 9,
          },
        },
      };
      // pdfMake.tableLayouts = {
      //   exampleLayout: {
      //     hLineWidth: function (i, node) {
      //       if (i === 0 || i === node.table.body.length) {
      //         return 0;
      //       }
      //       return i === node.table.headerRows ? 2 : 1;
      //     },
      //     vLineWidth: function (i) {
      //       return 0;
      //     },
      //     hLineColor: function (i) {
      //       return i === 1 ? 'black' : '#aaa';
      //     },
      //     paddingLeft: function (i) {
      //       return i === 0 ? 0 : 8;
      //     },
      //     paddingRight: function (i, node) {
      //       return i === node.table.widths.length - 1 ? 0 : 8;
      //     },
      //   },
      // };
      // console.log(this.arr_init);
      pdfMake.createPdf(docDefinition).download("คิวอาร์โค้ดทรัพย์สิน");
    },
    async qrcode() {
      // console.log(this.rows);
      // const { access_token } = await this.access_token();
      // const head = {
      //   headers: {
      //     Authorization: await access_token,
      //   },
      // };

      // this.rows.forEach(async (element) => {
      //   const url = `${API}files/qrcode/${element.qrcode}`;
      //   const res_s = await axios.get(url, head);
      //   console.log(res_s);
      //   // Define the string
      //   var decodedStringBtoA = res_s.data;

      //   // Encode the String
      //   var encodedStringBtoA = btoa(decodedStringBtoA);

      //   console.log(encodedStringBtoA);

      //   // this._arrayBufferToBase64(res_s);
      //   const imgData = this._arrayBufferToBase64(res_s);
      //   console.log(imgData);

      //   var arrayBuffer = res_s.data; // Note: not oReq.responseText

      //   var u8 = new Uint8Array(arrayBuffer);
      //   console.log(u8);
      //   var b64encoded = this.bufferToBase64(u8);
      //   console.log(b64encoded);
      // });

      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Medium.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageSize: "A4",
        pageOrientation: "portrait",
        info: {
          title: "คิวอาร์โค้ดทรัพย์สิน",
        },
        content: [
          {
            text: "คิวอาร์โค้ดทรัพย์สิน",
            style: "header",
            margin: [0, 0, 0, 30],
          },
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            style: "header",
          },

          {
            columns: [
              {
                text: `เลขทะเบียน : ${this.rowData.equipment_code}`,
                style: "title",
              },
              {
                text: `วันที่ :  ${parseInt(this.rowData.admitdate.split("-")[2])} ${
                  this.month[parseInt(this.rowData.admitdate.split("-")[1])]
                } ${Number(this.rowData.admitdate.split("-")[0]) + 543}`,
                style: "title",
              },

              {
                text: `ปีงบประมาณ : ${this.rowData.budget_year}`,
                style: "title",
              },
            ],
          },
          {
            columns: [
              {
                text: `ชื่อทรัพย์สิน: ${this.rowData.e_name}`,
                style: "title",
              },
            ],
            margin: [0, 0, 0, 35],
          },

          {
            layout: "lightHorizontalLines", // optional
            table: {
              headerRows: 1,
              widths: ["auto", "*", "*", "*", "*", "auto"],

              body: [
                [
                  { text: "ลำดับที่", style: "tableHeader" },
                  { text: "หมายเลขทรัพย์สิน", style: "tableHeader" },
                  { text: "SERIAL NUMBER", style: "tableHeader" },
                  { text: "รายละเอียด", style: "tableHeader" },
                  { text: "สถานะ", style: "tableHeader" },

                  { text: "QRcode", style: "tableHeader" },
                ],
                ...this?.rows.map((res, index) => {
                  return [
                    { text: index + 1, style: "text_center" },
                    { text: res.e_number, style: "text_center" },
                    { text: res.serial_number, style: "text_center" },
                    { text: res.information, style: "text_center" },
                    [{ text: res.reject_status, style: "text_center" }],
                    {
                      table: {
                        body: [
                          [
                            {
                              qr: res.e_number,
                              fit: "50",
                              width: "*",
                              style: "text_center",
                            },
                          ],
                          [{ text: res.e_number, style: "text_center" }],
                          [{ text: res.serial_number, style: "text_center" }],
                        ],
                      },
                    },
                  ];
                }),
              ],
            },
          },
        ],

        defaultStyle: {
          font: "Kanit",
        },

        styles: {
          tableExample: {
            margin: [0, 5, 0, 0],
            alignment: "center",
          },
          tableHeader: {
            bold: true,
            fontSize: 9,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
            alignment: "center",
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 15, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            alignment: "center",
            fontSize: 7,
          },
          text: {
            fontSize: 9,
          },
        },
      };
      // pdfMake.tableLayouts = {
      //   exampleLayout: {
      //     hLineWidth: function (i, node) {
      //       if (i === 0 || i === node.table.body.length) {
      //         return 0;
      //       }
      //       return i === node.table.headerRows ? 2 : 1;
      //     },
      //     vLineWidth: function (i) {
      //       return 0;
      //     },
      //     hLineColor: function (i) {
      //       return i === 1 ? 'black' : '#aaa';
      //     },
      //     paddingLeft: function (i) {
      //       return i === 0 ? 0 : 8;
      //     },
      //     paddingRight: function (i, node) {
      //       return i === node.table.widths.length - 1 ? 0 : 8;
      //     },
      //   },
      // };
      pdfMake.createPdf(docDefinition).download("คิวอาร์โค้ดทรัพย์สิน");
    },
    formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "รายการทรัพย์สินคงคลัง",
        columns: [
          {
            title: "รายการทรัพย์สินคงคลัง",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "หมายเลขทรัพย์สิน",
                field: "e_number",
              },
              {
                title: "Serial Number",
                field: "serial_number",
              },
              {
                title: "รายละเอียด",
                field: "information",
              },
              {
                title: "หน่วยนับ",
                field: "unittype",
              },
              {
                title: "ราคาต่อหน่วย",
                field: "unitprice_ex",
                type: "number",
              },
              {
                title: "ราคาต่อหน่วย + vat 7%",
                field: "unitprice_vat_ex",
                type: "number",
              },
              {
                title: "สถานะ",
                field: "reject_status",
              },
            ],
          },
        ],
      });
    },
    perPageChanged(value) {
      console.log(value);
      this.pageLength = value.currentPerPage;
      this.gettable();
    },
    pageChanged(value) {
      console.log(value);
      this.currentPage = value.currentPage;
      this.gettable();
    },
    async gettable() {
      console.log(this.rowData.e_id);
      this.show = true;
      const { equipment_code } = this.rowData;
      const { access_token } = await this.access_token();
      const url = `${API}inventoryList?equipment_code=${equipment_code}&e_id=${this.rowData.e_id}&refkey=${this.rowData.refkey}&_limit=${this.pageLength}&_page=${this.currentPage}`;
      this.token = access_token;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      console.log(res.data.message);
      this.total = res.data.message.total;
      this.rows = res.data.message.data.map((el) => {
        return {
          ...el,
          unitprice_ex: Number(el.unitprice),
          unitprice_vat_ex: Number(el.unitprice_vat),
        };
      });
      console.log(this.rows);

      this.show = false;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
